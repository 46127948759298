


























































































































































































































































































































































import { IClient } from '@/interfaces/client';
import { IClientUser } from '@/interfaces/clientuser';
import { IClientUserType } from '@/interfaces/clientusertype';
import { IPermission } from '@/interfaces/permission';
import { IRole } from '@/interfaces/role';
import { IUser } from '@/interfaces/user';

import Base from '@/mixins/Base.vue';

export default Base.extend({
    data() {
        return {
            isAddUserModalActive: false,
            selectedUserIndex: 0 as any,
            newUser: {
                client_user: {
                    client_user_type: undefined as any|IClientUserType,
                    role: undefined as any|IRole,
                    extra_permissions: [] as Array<IPermission>,
                } as IClientUser,
            } as IUser,
        };
    },
    props: {
        client: {
            type: Object as () => IClient,
            default: null,
        },
    },
    methods: {
        addUser() {
            if (this.client.users) {
                if (this.selectedUserIndex === undefined) {
                    this.client.users.push(this.newUser);
                    this.newUser = {
                        id: undefined,
                        client_user: {
                            client_user_type: {} as IClientUserType,
                            role: {} as IRole,
                            extra_permissions: [] as Array<IPermission>,
                        } as IClientUser,
                    };
                } else {
                    this.$set(this.client.users, this.selectedUserIndex, this.newUser);
                    this.selectedUserIndex = undefined;
                }

                this.isAddUserModalActive = false;
            }
        },
        editUser(index: number) {
            if (this.client.users) {
                this.newUser = this.client.users[index];
                this.selectedUserIndex = index;

                this.isAddUserModalActive = true;
            }
        },
        removeUser(index: number) {
            this.confirm().then(() => {
                if (this.client.users) this.$delete(this.client.users, index);
            });
        },
    },
});
