






































































import { IClient } from '@/interfaces/client';
import Base from '@/mixins/Base.vue';

import prices from '@/components/Prices.vue';
import { IService } from '@/interfaces/service';

export default Base.extend({
    components: {
        prices,
    },
    props: {
        client: {
            type: Object as () => IClient,
            default: null,
        },
    },
    methods: {
        getPrices() {
            if (this.client.services) {
                this.client.services.forEach((p: IService) => {
                    const service = p;

                    if (!service.prices) {
                        this.$http.get(`services/${service.id}/prices`).then(({ data }) => {
                            this.$set(service, 'prices', data.data);
                        });
                    }
                });
            }
        },
    },
});
