




































































































































































































import Base from '@/mixins/Base.vue';

import { IClient, IClientResponse } from '@/interfaces/client';
import { IContact } from '@/interfaces/contact';
import { ISource } from '@/interfaces/source';
import { IService } from '@/interfaces/service';
import { IUser } from '@/interfaces/user';
import { IPermission } from '@/interfaces/permission';
import { IAddress } from '@/interfaces/address';
import billingSettings from './partials/settings/BillingSettings.vue';
import contacts from './partials/settings/Contacts.vue';
import addresses from './partials/settings/Addresses.vue';
import invoicesAgreement from './partials/settings/InvoicesAgreement.vue';
import services from './partials/create/Service.vue';
import users from './partials/create/Users.vue';
import general from './partials/General.vue';

const component = Base.extend({
    components: {
        contacts,
        addresses,
        general,
        billingSettings,
        invoicesAgreement,
        services,
        users,
    },
    data() {
        return {
            activeStep: 'general',
            active: false,
            client: {
                services: [] as Array<IService>,
                users: [] as Array<IUser>,
                name: '',
                language: {
                    id: 1,
                    name: 'English',
                },
                email: '',
                phone: '',
                active: true,
                tax_rate: 0.21,
                sources: [] as Array<ISource>,
                private_professional: 'professional',
                addresses: [] as Array<IAddress>,
                contacts: [
                    { email: '', type: 'planning' },
                    { phone: '', email: '', type: 'acclienting' },
                    { email: '', type: 'report' },
                    { email: '', type: 'invoice' },
                    { phone: '', email: '', type: 'accounts' },
                    { email: '', type: 'order' },
                ] as Array<IContact>,
            } as IClient,
        };
    },

    mounted() {
        this.active = true;
    },
    methods: {
        saveClient(): void {
            let pdcts = [] as Array<IService>;
            let usrs = [] as Array<IUser>;
            if (this.client.services) {
                pdcts = this.client.services;
            }

            if (this.client.users) {
                usrs = this.client.users;
            }

            const loginUrl: string = `${window.location.protocol}//${window.location.host}${this.$router.resolve({ name: 'auth.login' }).href}`;

            this.post<IClientResponse>('clients', {
                ...this.client,
                sources: this.client.sources.map((s: ISource) => s.id),
                language_id: this.client.language ? this.client.language.id : null,
                client_type_id: this.client.client_type ? this.client.client_type.id : null,
                parent_client_id: this.client.parent_client ? this.client.parent_client.id : null,
                contacts: this.client.contacts.map((c: IContact) => ({
                    id: c.id,
                    name: c.name,
                    type: c.type,
                    email: c.email,
                    note: c.note,
                    phone: c.phone,
                })),
                services: pdcts.map((c: IService) => ({
                    id: c.id,
                    prices: c.prices,
                })),
                users: usrs.map((user: IUser) => ({
                    username: user.username,
                    firstname: user.firstname,
                    lastname: user.lastname,
                    email: user.email,
                    password: user.password,
                    password_confirmation: user.password_confirmation,
                    generic_role: user.generic_role ? user.generic_role.name : null,
                    client_user: {
                        role: user.client_user.role.name,
                        extra_permissions: user.client_user.extra_permissions.map((p: IPermission) => p.id),
                        client_user_type_id: user.client_user.client_user_type.id,
                    },
                    login_url: loginUrl,
                })),
            }).then(({ data }) => {
                this.$router.push({ name: 'clients.edit', params: { id: data.data.id.toString() } });
            });
        },
    },
});

export default component;
